import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import FeaturedImages from '../components/FeaturedImages'
import IntroParagraph from '../components/IntroParagraph'
import Hours from '../components/Hours'
import Tagline from '../components/Tagline'
import Menu from '../components/Menu'
import TaglineTwo from '../components/TaglineTwo'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Order from '../components/Order'

export const query = graphql`
  query OrderTestPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      facebook
      instagram
      favicon {
        asset {
          url
        }
      }
    }
    logo: sanityLogo {
      logo {
        asset {
          url
        }
      }
      alt
    }
    navigation: sanityNavigation {
      navigationItems
    }
    hero: sanityHero {
      backgroundImage {
        asset {
          url
        }
      }
      alt
      tagline
    }
    featuredImages: sanityFeaturedImages {
      images {
        image {
          asset {
            url
          }
        }
        alt
      }
    }
    introParagraph: sanityIntroParagraph {
      title
      description
    }
    hours: sanityHours {
      hours {
        days
        hoursOpen
      }
    }
    tagline: sanityTagline {
      tagline
      backgroundImage {
        asset {
          url
        }
      }
      alt
    }
    menu: sanityMenu {
      title
      description
      categories {
        title
        menuItems {
          title
          description
          price
          highlight
          # image {
          #   asset {
          #     fluid(maxWidth: 100, maxHeight: 100) {
          #       src
          #     }
          #   }
          # }
        }
      }
    }
    taglineTwo: sanityTaglineTwo {
      taglineTwo
      backgroundImage {
        asset {
          url
        }
      }
      alt
    }
    contact: sanityContact {
      title
      name
      address
      phone
      email
      googleMap
    }
  }
`

const OrderTestPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout logo={data.logo} navigation={data.navigation}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        favicon={site.favicon}
      />
      <Order />
      <Hero hero={data.hero} />
      <FeaturedImages featuredImages={data.featuredImages} />
      <IntroSectionWrapper>
        <IntroParagraph introParagraph={data.introParagraph} />
        <Hours hours={data.hours} />
      </IntroSectionWrapper>
      <Tagline tagline={data.tagline} />
      <Menu menu={data.menu} />
      <TaglineTwo taglineTwo={data.taglineTwo} />
      <Contact contact={data.contact} />
      <Footer site={data.site} contact={data.contact} />
    </Layout>
  )
}

const IntroSectionWrapper = styled.section`
  margin: 8rem auto;

  @media ${theme.media.tablet} {
    margin: 4rem auto;
  }
`

export default OrderTestPage
